// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cJ71c6mOE", "sTw4zSYE2"];

const variantClassNames = {cJ71c6mOE: "framer-v-uba77s", sTw4zSYE2: "framer-v-mygd9t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Burger: "cJ71c6mOE", X: "sTw4zSYE2"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, iWF8EWIN1: color ?? props.iWF8EWIN1 ?? "rgb(136, 136, 136)", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cJ71c6mOE"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, iWF8EWIN1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cJ71c6mOE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap13c8can = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("sTw4zSYE2")
})

const onTap12gd94z = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("cJ71c6mOE")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-VuDDN", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-uba77s", className)} data-framer-name={"Burger"} data-highlight layoutDependency={layoutDependency} layoutId={"cJ71c6mOE"} onTap={onTap13c8can} ref={ref} style={{...style}} {...addPropertyOverrides({sTw4zSYE2: {"data-framer-name": "X", onTap: onTap12gd94z}}, baseVariant, gestureVariant)}><motion.div className={"framer-1i3kkwf"} layoutDependency={layoutDependency} layoutId={"vBpdKF8Et"}><motion.div className={"framer-1nxbtrc"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"XCI51ZVrH"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{sTw4zSYE2: {rotate: -45}}}/><motion.div className={"framer-fwdtoj"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"IRScYVyGe"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} variants={{sTw4zSYE2: {opacity: 0}}}/><motion.div className={"framer-vpmaf0"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"zzdP9uYHP"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{sTw4zSYE2: {rotate: 45}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-VuDDN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VuDDN .framer-1053znh { display: block; }", ".framer-VuDDN .framer-uba77s { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-VuDDN .framer-1i3kkwf { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-VuDDN .framer-1nxbtrc { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-VuDDN .framer-fwdtoj { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-VuDDN .framer-vpmaf0 { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-VuDDN.framer-v-mygd9t .framer-1nxbtrc { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-VuDDN.framer-v-mygd9t .framer-fwdtoj { left: unset; width: 2px; }", ".framer-VuDDN.framer-v-mygd9t .framer-vpmaf0 { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"sTw4zSYE2":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const FramerUVs2C5EdR: React.ComponentType<Props> = withCSS(Component, css, "framer-VuDDN") as typeof Component;
export default FramerUVs2C5EdR;

FramerUVs2C5EdR.displayName = "Elements/Menu Icon";

FramerUVs2C5EdR.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerUVs2C5EdR, {variant: {options: ["cJ71c6mOE", "sTw4zSYE2"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerUVs2C5EdR, [])